import { Link } from "gatsby"
import React from "react"
import { contactPageURL, teamEnquiryURL, teamsPageURL } from "../../site/urls"
import { getWhatsAppURL } from "../../site/utils"
import "./TeamCard.scss"
import noImg from "../../images/no-image.png"
import { ImageModule } from "../common/Image_Module"

const TeamCard = ({ team, normalImg, hideContact }) => {
  const { id, tile_image, name, designation, mobile_no, imagetransforms, ggfx_results } = team

  let processedImages = JSON.stringify({})
  if (imagetransforms?.tile_image_Transforms) {
    processedImages = imagetransforms.tile_image_Transforms
  }

  return (
    <div key={id} className="team-card-wrap">
      {
        <Link
          to={teamsPageURL + team.slug + "/"}
          className="team-img-wrap img-zoom"
        >
          {tile_image ? (
            normalImg && (
              <ImageModule
                imagename={"teams.tile_image.tileimg"}
                ImageSrc={tile_image}
                ggfx_results={ggfx_results}
                altText={name}
                strapi_id={id}
                className="team-img"
              />
            )
          ) : (
            <img src={noImg} alt={"teams - img"} className="team-img" />
          )}
        </Link>
      }
      <div className="team-content">
        <Link to={teamsPageURL + team.slug + "/"} className="name">
          {name}
        </Link>
        <p className="designition">{designation}</p>
      </div>
      {!hideContact && (
        <div className="team-contact">
          {mobile_no && (
            <>
              <a
                href={getWhatsAppURL(mobile_no)}
                className="icon icon-whatsapp-green"
                target={"_blank"}
              ></a>
              <a href={`tel:${mobile_no}`} className="icon icon-mobile"></a>
            </>
          )}
          <Link
            to={teamEnquiryURL}
            state={{ team }}
            className="icon icon-mail"
          ></Link>
        </div>
      )}
    </div>
  )
}

export default TeamCard
